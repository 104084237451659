import { React } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Agendamento from './components/Agendamento';
import TabelaPreco from './components/TabelaPreco';
import Condicao from './components/Condicao';
import Header from './components/Header';
import Login from './components/Login';
import Main from './components/Main';
import IsAutenticated from './auth';

const PrivateRoute = () => {
    IsAutenticated();
    const auth = sessionStorage.getItem('auth')
    if(auth === 'true') {
        return <Outlet />;
    } else {
        return <Navigate to="/" />;
    }
}

const RoutesApi = () => {
    return (
        <BrowserRouter>
            { PrivateRoute().props.to === "/" || window.location.pathname === "/" ? <></> : <Header/> }
            <Routes>
                <Route path='*' element={
                    <center><h2>Pagina não encontrada!</h2></center>
                } />
                <Route path='/' element={<Login/>} />
                <Route path='/inicio' element={<PrivateRoute/>}>
                    <Route path='/inicio' element={<Main/>}/>
                </Route>
                <Route path='/agendamento/:id' element={<PrivateRoute/>}>
                    <Route path='/agendamento/:id' element={<Agendamento/>}/>
                </Route>
                <Route path='/agendar-condicao' element={<PrivateRoute/>}>
                    <Route path='/agendar-condicao' element={<Condicao/>}/>
                </Route>
                <Route path='/agendar-preco' element={<PrivateRoute/>}>
                    <Route path='/agendar-preco' element={<TabelaPreco/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApi;