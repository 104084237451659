import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../services/api';

const Agendamento = () => { 
    const [agendamento, setAgendamento] = useState({});
    const [qtdItens, setQtdItens] = useState(0);
    const [itens, setItens] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        api.get(`/agendamento/${id}`)
        .then(res => {
            setAgendamento(res.data.agendamento[0])
            setItens(res.data.itens)
            setQtdItens(res.data.qtd_itens)
        })
        .catch(error => {
            console.log(error)
            window.location.replace('/');
        });
    }, [id]);

    return(
        <div className="container-fluid" style={{ marginTop: '11vh'}}>
            <div className="card mt-4 shadow bg-white rounded" style={{ height: '85vh', overflowY: 'auto' }}>
                <div className="row mx-1" style={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: '#fff' }}>
                    <div className="row mt-3">
                        <p className="mb-2 fw-bold col-6" style={{ color: '#222831' }}>Agendamento: <small style={{ color: '#0C359E' }}>#{agendamento.id} - {agendamento.descricao}</small></p>
                        <p className="mb-2 fw-bold col-3" style={{ color: '#222831' }}>Criação: <small style={{ color: '#0C359E' }}>{agendamento.data_criacao}</small></p>
                        <p className="mb-2 fw-bold col-3" style={{ color: '#222831' }}>Execução: <small style={{ color: '#0C359E' }}>{agendamento.data_execucao}</small></p>
                    </div>
                    <div className="row">
                        <p className="mb-2 fw-bold col" style={{ color: '#222831' }}>Tipo: <small style={{ color: '#0C359E' }}>{agendamento.tipo}</small></p>
                        <p className="mb-2 fw-bold col" style={{ color: '#222831' }}>Criador: <small style={{ color: '#0C359E' }}>{agendamento.criador}</small></p>
                        <p className="mb-2 fw-bold col" style={{ color: '#222831' }}>Situação: <small style={{ color: '#0C359E' }}>{agendamento.situacao}</small></p>
                        <p className="mb-2 fw-bold col" style={{ color: '#222831' }}>Qtd Itens: <small style={{ color: '#0C359E' }}>{qtdItens}</small></p>  
                    </div>
                </div>
                <div className="card-body">
                    <table className="table table-hover">
                        <thead className="shadow-sm p-3 mb-5 bg-white rounded" style={{ position: 'sticky', top: '70px', zIndex: 1 }}>
                            <tr style={{ color: '#222831' }}>
                                <th scope="col">Codigo</th>
                                <th scope="col">Descrição</th>
                                <th scope="col">Fornecedor</th>
                                { agendamento.id_tipo !== 1 ? 
                                <>
                                    <th scope="col">Condição</th>
                                    <th scope="col">Desconto</th>
                                    <th scope="col">Preço</th>
                                    <th scope="col">Qtd 1</th>
                                    <th scope="col">Desc 1</th>
                                    <th scope="col">Qtd 2</th>
                                    <th scope="col">Desc 2</th>
                                    <th scope="col">Qtd 3</th>
                                    <th scope="col">Desc 3</th>
                                    <th scope="col">Qtd 4</th>
                                    <th scope="col">Desc 4</th>
                                    <th scope="col">Qtd 5</th>
                                    <th scope="col">Desc 5</th>
                                </>
                                :
                                <>
                                    <th scope="col">Tabela</th>
                                    <th scope="col">Preço</th>
                                </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                        {itens.map((i) =>
                            <tr key={`${i.cod_produto}-${i.id_condicao}`}>
                                <td>{i.cod_produto}</td>
                                <td>{i.descricao}</td>
                                <td>{i.fornecedor}</td>
                                { agendamento.id_tipo !== 1 ? 
                                <>
                                    <td>{i.id_condicao}</td>
                                    <td>{i.desconto ? parseFloat(i.desconto).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.preco ? parseFloat(i.preco).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.qtd_1}</td>
                                    <td>{i.desc_1 ? parseFloat(i.desc_1).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.qtd_2}</td>
                                    <td>{i.desc_2 ? parseFloat(i.desc_2).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.qtd_3}</td>
                                    <td>{i.desc_3 ? parseFloat(i.desc_3).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.qtd_4}</td>
                                    <td>{i.desc_4 ? parseFloat(i.desc_4).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.qtd_5}</td>
                                    <td>{i.desc_5 ? parseFloat(i.desc_5).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                </>
                                :
                                <>
                                    <td>{i.tabela_preco}</td>
                                    <td>{i.preco ? parseFloat(i.preco).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                </>
                                }
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default Agendamento;