import axios from 'axios';

const api = axios.create({
    baseURL: 'https://bot.riosfarma.com.br/api',
    //baseURL: 'http://localhost:8080/api',
});

api.interceptors.request.use(
    config => {
        config.headers['authorization'] = sessionStorage.getItem("token");
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default api;