import { React, useState, useEffect } from 'react';
import api from '../services/api';

const Main = () => { 
    const [agendamentos, setAgendamentos] = useState([]);

    useEffect(() => {
        api.get('/agendamentos')
        .then(res => {
            setAgendamentos(res.data)
        })
        .catch(error => {
            console.log(error)
            window.location.replace('/');
        });
    }, []);

    const excluir = (id) => {
        const confirmarExclusao = window.confirm("Deseja realmente excluir este agendamento?");
        if (confirmarExclusao) {
          api.delete(`/agendamento/${id}`)
          .then(res => {
            window.location.reload();
          })
          .catch(error => {
            console.log(error)
            window.location.replace('/');
          });
        } 
    }

    return(
        <div className="container mt-5">
            <div className="card mt-4 shadow p-3 bg-white rounded" style={{ height: '85vh', overflowY: 'auto' }}>
                <div className="card-body">
                    <table className="table table-hover row-12">
                        <thead style={{ position: 'sticky', top: -15, zIndex: 1 }}>
                            <tr style={{ color: '#222831' }}>
                                <th scope="col">ID</th>
                                <th scope="col">Descrição</th>
                                <th scope="col">Criação</th>
                                <th scope="col">Execução</th>
                                <th scope="col">Criador</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Situação</th>
                                <th scope="col">Operação</th>
                            </tr>
                        </thead>
                        <tbody>
                        { agendamentos.map((a) =>
                            <tr key={a.id}>
                                <td>{`#${a.id}`}</td>
                                <td>{a.descricao}</td>
                                <td>{a.data_criacao}</td>
                                <td>{a.data_execucao}</td>
                                <td>{a.criador}</td>
                                <td>{a.tipo}</td>
                                <td>{a.situacao}</td>
                                <td>
                                {a.id_situacao === 1 ? 
                                    <a className="btn btn-sm me-2" href={`/agendamento/${a.id}`} style={{ backgroundColor: '#0C359E', color: '#fff' }}>Detalhes</a>
                                 :
                                 <>
                                    <a className="btn btn-sm me-2" href={`/agendamento/${a.id}`} style={{ backgroundColor: '#0C359E', color: '#fff' }}>Detalhes</a>
                                    <button className="btn btn-sm" style={{ backgroundColor: '#222831', color: '#fff' }} onClick={()=> excluir(a.id)}>Excluir</button>   
                                 </>
                                }
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default Main;