import api from './services/api';
import { jwtDecode } from 'jwt-decode';

const IsAutenticated = () => {
    if (sessionStorage.getItem("token")) {
        const { id_usuario } = jwtDecode(sessionStorage.getItem("token"));
        api.interceptors.request.use(
            config => {
                config.headers['authorization'] = sessionStorage.getItem("token");
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
        api.get(`/user/${id_usuario}`)
        .then(res => {
            sessionStorage.setItem("auth", true)
        })
        .catch(error => {
            sessionStorage.setItem("auth", false)
        });
    }
}
export default IsAutenticated;