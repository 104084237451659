import { React, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import api from '../services/api';

const Condicao = () => { 
    const [itens, setItens] = useState([]);
    const [descricao, setDescricao] = useState({});
    const [data, setData] = useState({});
    const [hora, setHora] = useState({});
    const [reload, setReload] = useState(false);

    const { id_usuario } = jwtDecode(sessionStorage.getItem("token"));

    const handleDesc = (event) => {
        setDescricao(event.target.value)
    }
    const handleData = (event) => {
        setData(event.target.value)
    }
    const handleHora = (event) => {
        setHora(event.target.value)
    }
    const handleImport = (event) => {
        const file = event.target.files[0];
        if (file) {
            const data_execucao = `${data} ${hora}`;
            const tipo = 0;

            const dataJson = {
                descricao,
                data_execucao,
                id_usuario,
                id_tabela_preco: null,
                tipo
            }

            api.post('/agendamento', dataJson)
            .then(res => {
                const id_agendamento = res.data.id
                const formData = new FormData();
                formData.append('file', file);
                
                api.post(`/agendamento/${id_agendamento}/importar`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(res => {
                    setItens([]);
                    setReload(true)
                    setTimeout(() => {
                        api.get(`/agendamento/${id_agendamento}`)
                        .then(res => {
                            setReload(false)
                            setItens(res.data.itens);
                        })
                        .catch(error => {
                            console.log(error)
                            window.location.replace('/');
                        });
                    }, 5000);
                })
                .catch(error => {
                    console.error(error);
                    window.location.replace('/');
                });
            })
            .catch(error => {
                console.log(error)
                window.location.replace('/');
            });
            event.target.value = '';
        } 
    };

    return(
        <div className="container-fluid" style={{ marginTop: '11vh'}}>
            <div className="card mt-4 shadow bg-white rounded" style={{ height: '85vh', overflowY: 'auto' }}>
                <div className="card-body">
                    <div className="row" style={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: '#fff' }}>
                        <center>
                            <h3 className="mb-3 mt-3">Agendar Atualização de Condições Comerciais</h3>
                        </center>
                        <div className="mb-2 col-5">
                            <label htmlFor="descricao" className="form-label">Descrição</label>
                            <input type="text" className="form-control" id="descrição" onChange={handleDesc}/>
                        </div>
                        <div className="mb-2 col-3">
                            <label htmlFor="data" className="form-label">Data Execução</label>
                            <input type="date" className="form-control" id="data" onChange={handleData}/>
                        </div>
                        <div className="mb-2 col-3">
                            <label htmlFor="hora" className="form-label">Hora Execução</label>
                            <input type="time" className="form-control" id="hora" onChange={handleHora}/>
                        </div>
                        <div className="col-1">
                            <button type="button" className="btn mt-4" style={{ backgroundColor: '#222831', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => document.getElementById('fileInput').click()}>
                                Importar
                                <span className="material-icons" style={{ marginLeft: '8px' }}>
                                    cloud_upload
                                </span>
                            </button>
                            <input type="file" id="fileInput" style={{ display: 'none' }} accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleImport} />
                        </div>
                    </div>
                    <div className="row">
                        <table className="table table-hover">
                            <thead className="shadow-sm p-3 mb-5 bg-white rounded" style={{ position: 'sticky', top: '142px', zIndex: 1 }}>
                                <tr style={{ color: '#222831' }}>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Fornecedor</th>
                                    <th scope="col">Condição</th>
                                    <th scope="col">Desconto</th>
                                    <th scope="col">Preço</th>
                                    <th scope="col">Qtd 1</th>
                                    <th scope="col">Desc 1</th>
                                    <th scope="col">Qtd 2</th>
                                    <th scope="col">Desc 2</th>
                                    <th scope="col">Qtd 3</th>
                                    <th scope="col">Desc 3</th>
                                    <th scope="col">Qtd 4</th>
                                    <th scope="col">Desc 4</th>
                                    <th scope="col">Qtd 5</th>
                                    <th scope="col">Desc 5</th>
                                </tr>
                            </thead>
                            {reload && (
                                <tr>
                                    <td colSpan="16" style={{ textAlign: "center" }}>
                                        <div className="spinner-border text-dark" role="status" style={{ marginTop: '25vh' }}>
                                            <span className="sr-only"></span>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            <tbody>
                            {itens.map((i) =>
                                <tr key={`${i.cod_produto}-${i.id_condicao}`}>
                                    <td>{i.cod_produto}</td>
                                    <td>{i.descricao}</td>
                                    <td>{i.fornecedor}</td>
                                    <td>{i.id_condicao}</td>
                                    <td>{i.desconto ? parseFloat(i.desconto).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.preco ? parseFloat(i.preco).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.qtd_1}</td>
                                    <td>{i.desc_1 ? parseFloat(i.desc_1).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.qtd_2}</td>
                                    <td>{i.desc_2 ? parseFloat(i.desc_2).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.qtd_3}</td>
                                    <td>{i.desc_3 ? parseFloat(i.desc_3).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.qtd_4}</td>
                                    <td>{i.desc_4 ? parseFloat(i.desc_4).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td>{i.qtd_5}</td>
                                    <td>{i.desc_5 ? parseFloat(i.desc_5).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Condicao;