import { React, useState } from 'react';
import api from '../services/api';
import './styles/Login.css';

const Login = () => {

  const [email, setEmail] = useState({});
  const [senha, setSenha] = useState({});
  const [error, setError] = useState(null);

  const handleEmail = (event) => {
    setEmail(event.target.value)
  }
  const handleSenha = (event) => {
    setSenha(event.target.value)
  }

  const login = () => {
    if (email.length > 0 && senha.length > 0) {
      const usuario = {
        usuario: email,
        senha: senha
      }

      api.post('/auth', usuario)
      .then(res => {
        sessionStorage.setItem("token", res.data.token)
        sessionStorage.setItem("auth", true)
        window.location.replace('/inicio')
      })
      .catch(error => {
        setError(error.response.data.message)
      });
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      login();
    }
  }

  return (
    <div className="container-fluid login-background">
      <div className="row mt-2">
        {
          error !== null ? (
            <div className="alert alert-warning" role="alert" style={{ width: '25rem' }}>
              {error}
            </div>
          ) : null
        }
      </div>
      <div className="row mt-5">
        <div className="card mt-2 shadow p-3 mb-5 bg-white rounded" style={{ width: '18rem', color: '#222831' }}>
          <div className="card-body">
            <h2 className="mb-3">Login</h2>
            <div className="mb-2">
              <label htmlFor="email" className="form-label">E-mail</label>
              <input type="email" className="form-control" id="email" onChange={handleEmail}/>
            </div>
            <div className="mb-2">
              <label htmlFor="senha" className="form-label">Senha</label>
              <input type="password" className="form-control" id="senha" onChange={handleSenha} onKeyDown={handleKeyDown} />
            </div>
            <button className="btn mt-3 mb-3" style={{ backgroundColor: '#222831', color: '#fff' }} onClick={login}>Entrar</button>
            <p>
              <small>&copy; Copyright 2024, <a href="https://scriptsky.com.br/">Scriptsky System</a></small>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;
