import { React, useState, useEffect } from 'react';
import Logo from '../assets/logowhite.png'
import { jwtDecode } from 'jwt-decode';
import api from '../services/api';

const Header = () => { 

  const { id_usuario } = jwtDecode(sessionStorage.getItem("token"));
  const [usuario, setUsuario] = useState({});

  useEffect(() => {
    api.get(`/user/${id_usuario}`)
    .then(res => {
      setUsuario(res.data[0])
    })
    .catch(error => {
      console.log(error)
      window.location.replace('/');
    });
  }, [id_usuario]);

  const logoff = () => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("auth");
      window.location.replace('/');
  }

  return(
    <nav className="navbar navbar-expand-lg fixed-top" style={{ backgroundColor: '#222831' }}>
      <div className="container-fluid">
        <a className="navbar-brand text-light" href="/inicio">
          <img src={Logo} width="30" height="30" alt="Logo Scriptsky" />
          <span className="nav-item text-light mx-2">Updaterapid</span>
        </a>
        <ul className="navbar-nav me-auto mb-lg-0">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-light" href="/#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Agendar
            </a>
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" href="/agendar-condicao">Atualização de Condições</a></li>
              <li><a className="dropdown-item" href="/agendar-preco">Atualização de Preços</a></li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link text-light" href="https://firebasestorage.googleapis.com/v0/b/updaterapid.appspot.com/o/modelo%20de%20importacao.xlsx?alt=media&token=94b4ef9c-8426-4f2c-8853-634830536738" role="button">
              Download Modelo Importação
            </a>
          </li>
        </ul>
        <div className="dropdown d-flex">
          <a className="nav-link dropdown-toggle text-light" href="/#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          { usuario.nome }
          </a>
          <ul className="dropdown-menu dropdown-menu-end">
            <li><a className="dropdown-item" href="/#" onClick={logoff}>Sair</a></li>
          </ul>
        </div>
      </div>
    </nav>
  )}
export default Header;